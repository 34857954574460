import React from "react"

import SEO from "../components/Seo"
import PageWrapper from "../components/PageWrapper"

const NotFoundPage = ({ location }) => (
  <PageWrapper location={location}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... sorry about that.</p>
  </PageWrapper>
)

export default NotFoundPage
